import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "row items-center justify-evenly" }
const _hoisted_2 = {
  key: 0,
  class: "fixed-right",
  style: {"z-index":"99"}
}
const _hoisted_3 = { class: "full-height column justify-center items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_scroll_observer = _resolveComponent("q-scroll-observer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content || [], (component) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(component.__typename)), {
        key: component,
        id: component.title,
        ref_for: true,
        ref: component.title,
        data: component,
        source_data: _ctx.content
      }, null, 8, ["id", "data", "source_data"]))
    }), 128)),
    _createVNode(_Transition, {
      appear: "",
      "enter-active-class": "animated bounceInRight",
      "leave-active-class": "animated bounceOutRight"
    }, {
      default: _withCtx(() => [
        (_ctx.$q.screen.gt.sm)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (component) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: component }, [
                    (component.title !== 'root')
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          ripple: false,
                          style: {"transition":"all .3s"},
                          flat: "",
                          "aria-label": component.title,
                          class: _normalizeClass(["q-pr-none", {
                                    'text-primary': _ctx.get_menu_visibility(component.title || '')
                                }]),
                          size: _ctx.get_menu_visibility(component.title || '') ? 'lg' : 'md',
                          to: { name: _ctx.$route.name, hash: `#${component.title}`, meta: _ctx.$route.meta }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(component.title) + " ", 1),
                            _createVNode(_component_q_icon, {
                              class: "q-ml-sm",
                              name: _ctx.get_menu_visibility(component.title || '') ? 'circle' : 'radio_button_unchecked'
                            }, null, 8, ["name"]),
                            _createElementVNode("hr", {
                              style: _normalizeStyle(`width: ${_ctx.get_menu_visibility(component.title || '') ? 25 : 10}px; transition: all .3s`)
                            }, null, 4)
                          ]),
                          _: 2
                        }, 1032, ["aria-label", "size", "to", "class"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])
            ], 512)), [
              [_vShow, _ctx.scrollInfo.position.top > _ctx.$q.screen.height / 2.5]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.$q.screen.gt.sm)
      ? (_openBlock(), _createBlock(_component_q_scroll_observer, {
          key: 0,
          onScroll: _ctx.onScroll
        }, null, 8, ["onScroll"]))
      : _createCommentVNode("", true)
  ]))
}