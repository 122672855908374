import gql from 'graphql-tag'

export default (lang: string) => {
    return gql`query {
        data:queryHomepageContents {
            data {
                content {
                    ${lang} {
                        ... on HomepageproductsComponent {
                            title
                            products {
                                data {
                                    name {
                                        ${lang}
                                    }
                                    commingSoon {
                                        ${lang}
                                    }
                                    page {
                                        ${lang} {
                                            data {
                                                refContent {
                                                    ${lang} {
                                                        label
                                                        labelShort
                                                        description
                                                        descriptionShort
                                                        image {
                                                            url
                                                            pixelWidth:metadata(path: "pixelWidth")
                                                            pixelHeight:metadata(path: "pixelHeight")
                                                            imageQuality:metadata(path: "imageQuality")
                                                            alt:metadata(path: "alt")
                                                            focusX:metadata(path: "focusX")
                                                            focusY:metadata(path: "focusY")
                                                        }
                                                    }
                                                }
                                                routing {
                                                    ${lang} {
                                                        routeName
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on HomepageheroComponent {
                            title
                            label
                            copyright
                            prefix
                            suffix
                        }
                        ... on HomepagecustomersComponent {
                            title
                            customers {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    name {
                                        iv
                                    }
                                    link {
                                        ${lang}
                                    }
                                    logoLight {
                                        iv {
                                            url
                                            pixelWidth:metadata(path: "pixelWidth")
                                            pixelHeight:metadata(path: "pixelHeight")
                                            imageQuality:metadata(path: "imageQuality")
                                            alt:metadata(path: "alt")
                                            focusX:metadata(path: "focusX")
                                            focusY:metadata(path: "focusY")
                                        }
                                    }
                                    logoDark {
                                        iv {
                                            url
                                            pixelWidth:metadata(path: "pixelWidth")
                                            pixelHeight:metadata(path: "pixelHeight")
                                            imageQuality:metadata(path: "imageQuality")
                                            alt:metadata(path: "alt")
                                            focusX:metadata(path: "focusX")
                                            focusY:metadata(path: "focusY")
                                        }
                                    }
                                    logo {
                                        iv {
                                            url
                                            pixelWidth:metadata(path: "pixelWidth")
                                            pixelHeight:metadata(path: "pixelHeight")
                                            imageQuality:metadata(path: "imageQuality")
                                            alt:metadata(path: "alt")
                                            focusX:metadata(path: "focusX")
                                            focusY:metadata(path: "focusY")
                                        }
                                    }
                                    description {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on HomepagereviewsComponent {
                            title
                            reviews {
                                data {
                                    name {
                                        iv
                                    }
                                    message {
                                        ${lang}
                                    }
                                    rating {
                                        iv
                                    }
                                    date {
                                        iv
                                    }
                                }
                            }
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on HomepageteamComponent {
                            title
                            persons {
                                data {
                                    name {
                                        iv
                                    }
                                    position {
                                        ${lang}
                                    }
                                    image {
                                        iv {
                                            url
                                            pixelWidth:metadata(path: "pixelWidth")
                                            pixelHeight:metadata(path: "pixelHeight")
                                            imageQuality:metadata(path: "imageQuality")
                                            alt:metadata(path: "alt")
                                            focusX:metadata(path: "focusX")
                                            focusY:metadata(path: "focusY")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }`
}
