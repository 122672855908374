
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { modeColors } from '@/helper/dark_mode_colors'

export default defineComponent({
    name: 'p_components_layout_footer',

    data () {
        const { locale } = useI18n({ useScope: 'global' })

        return {
            locale,
            locale_options: [
                { value: 'en-us', label: 'defaults.languages.english' },
                { value: 'de-de', label: 'defaults.languages.german' }
            ]
        }
    },

    methods: {
        toggle_dark_mode () {
            const m = this.$q.dark.mode.toString()
            let newM: boolean | 'auto' = 'auto'
            if (m === 'true') newM = false
            if (m === 'auto') newM = true

            this.$q.dark.set(newM)
            localStorage.setItem('dark_mode', this.$q.dark.mode.toString())

            modeColors(this.$q.dark.isActive)

            this.$store.dispatch('helper/A_TOGGLE_DARK', this.$q.dark.isActive)
        },

        set_language () {
            localStorage.setItem('language', this.locale)
        }
    }
})
