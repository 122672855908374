import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "q-my-md row full-width",
  style: {"position":"relative"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "full-width",
      style: _normalizeStyle(`border-bottom: 1px solid var(${ _ctx.$q.dark.isActive ? '--q-text3' : '--q-accent3' })`)
    }, null, 4),
    _createVNode(_component_q_icon, {
      class: "q-pa-sm",
      color: "pagisto",
      style: {"position":"absolute","top":"0","left":"50%","transform":"translate3d(-50%, -50%, 0)","z-index":"3","background":"var(--q-accent2)"},
      name: "token"
    })
  ]))
}