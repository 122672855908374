
import { defineComponent, ref } from 'vue'
import homepage_query from '@/schemas/homepage'
import { apolloProvider } from '@/boot/apollo'
import { API_Query_Data, API_Query_Language_Data_Helper } from '@/types/API'
import { CMS_COMPONENT_Homepagehero, CMS_SCHEMA_Homepage } from '@/types/cms/homepage'
import { get_short_locale } from '@/boot/i18n'
/* import { useMeta } from 'quasar' */

// components
import Divider from '@/components/layout/Divider.vue'

export default defineComponent({
    name: 'p_pages_index',

    setup () {
        const scrollInfo = ref({
            position: {
                top  : 0,
                left : 0
            }
        })

        return {
            scrollInfo,
            onScroll (info: { position: { top: number, left: number } }) {
                scrollInfo.value = info
            }
        }
    },

    components: {
        'p-divider': Divider
    },

    data () {
        return {
            content      : [new CMS_COMPONENT_Homepagehero()],
            lang         : get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : ''),
            init_request : true
        }
    },

    watch: {
        '$route.meta.lang': {
            handler () {
                if (!this.init_request) {
                    this.lang = get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : '')
                    this.get_data()
                } else {
                    this.init_request = false
                }
            },
            immediate: true
        }
    },

    methods: {
        async get_data () {
            const response: API_Query_Data<API_Query_Language_Data_Helper<CMS_SCHEMA_Homepage>> = await apolloProvider.defaultClient.query({
                query: homepage_query(this.lang)
            })

            this.content = Object.values(response.data.data[0].data.content)[1]
        },

        get_menu_visibility (selector: string) {
            const actual = this.scrollInfo.position.top
            if (selector && selector !== ''/*  && actual > 30 */) {
                const target_raw = this.$refs[selector]
                // const target = document.getElementById(selector)
                // @ts-ignore
                if (target_raw && target_raw !== null && target_raw.length === 1) {
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    const target: HTMLElement = target_raw[0].$el
                    const client_height = target.offsetHeight
                    const start = target.offsetTop
                    const end = client_height + start
                    const response = actual >= start - 250 && actual < end - 217
                    return response
                }
                return false
            }
            return false
        }
    },

    created () {
        this.get_data()
    }
})
